// extracted by mini-css-extract-plugin
export var pressCard__date = "cg_n5";
export var pressCard__description = "cg_n4";
export var pressCard__image_large = "cg_nX";
export var pressCard__image_small = "cg_nY";
export var pressCard__info = "cg_nZ";
export var pressCard__info_large = "cg_n0";
export var pressCard__info_small = "cg_n1";
export var pressCard__large = "cg_n6";
export var pressCard__link = "cg_nW";
export var pressCard__post = "cg_nV";
export var pressCard__small = "cg_nT";
export var pressCard__source = "cg_n2";
export var pressCard__title = "cg_n3";